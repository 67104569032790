<template>
  <transition>
    <div
      v-if="filterToggle"
    >
      <b-form
        class="border-dashed"
      >
        <b-row>
          <b-col md="3">
            <b-row class="flex-column">
              <!--Name-->
              <b-col md="12">
                <b-form-group
                  :label="filterClass.getLabel(Assets.NAME)"
                >
                  <b-form-input
                    v-model="filters[Assets.NAME]"
                    :placeholder="filterClass.getPlaceholder(Assets.NAME)"
                  />
                </b-form-group>
              </b-col>
              <!--Tags-->
              <b-col md="12">
                <b-form-group
                  :label="filterClass.getLabel(Assets.TAGS)"
                >
                  <l-select-filter
                    v-model="filters[Assets.TAGS]"
                    :field="filterClass.fields[Assets.TAGS]"
                    :name="Assets.TAGS"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="3">
            <b-row class="flex-column">
              <b-col md="12">
                <!--Model-->
                <b-form-group
                  :label="filterClass.getLabel(Assets.MODEL)"
                >
                  <b-form-input
                    v-model="filters[Assets.MODEL]"
                    :placeholder="filterClass.getPlaceholder(Assets.MODEL)"
                  />
                </b-form-group>
                <!--Pick Location-->
                <label
                  style="margin-bottom: 7px"
                  for="treeselect"
                >{{ $t('Pick Location') }}</label>
                <treeselect
                  id="treeselect"
                  v-model="filters[Assets.PICK_LOCATION]"
                  class="tree-select--custom--filter"
                  :options="inventoryWarehouseLocations"
                  :name="Assets.PICK_LOCATION"
                >
                  <div
                    slot="value-label"
                    slot-scope="{ node }"
                  >
                    {{ filters[Assets.PICK_LOCATION] ? filters[Assets.PICK_LOCATION].id ? filters[Assets.PICK_LOCATION].name : node.raw.optionLabel : null }}
                  </div>
                </treeselect>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="3">
            <b-row class="flex-column">
              <b-col md="12">
                <!--Brand-->
                <b-form-group
                  :label="filterClass.getLabel(Assets.BRAND)"
                >
                  <l-select-filter
                    v-model="filters[Assets.BRAND]"
                    :field="filterClass.fields[Assets.BRAND]"
                    :name="Assets.BRAND"
                  />
                </b-form-group>
                <!--CONDITION-->
                <b-form-group
                  :label="filterClass.getLabel(Assets.CONDITION)"
                >
                  <l-select-filter
                    v-model="filters[Assets.CONDITION]"
                    :field="filterClass.fields[Assets.CONDITION]"
                    :name="Assets.CONDITION"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="3">
            <b-row class="flex-column">
              <b-table-simple
                v-for="field in [
                  Assets.ALLOW_TO_SELL,Assets.IN_ACTIVE,Assets.DAMAGED,Assets.SERVICE,Assets.SUBRENTAL
                ]"
                :key="field"
              >
                <b-row class="checkboxGroup">
                  <b-col
                    md="4"
                    class="d-flex justify-content-end"
                  >
                    <b-form-checkbox
                      :id="field"
                      v-model="filters[Assets.PRODUCT_RULES][field]"
                      class="checkbox ml-auto"
                      value="1"
                      :unchecked-value="null"
                    />
                  </b-col>
                  <b-col md="8">
                    <b-thead>
                      <label
                        :for="field"
                        class="checkboxLabel"
                      >
                        {{ filterClass.getLabel(Assets.PRODUCT_RULES, field) }}
                      </label>
                    </b-thead>
                  </b-col>

                </b-row>
              </b-table-simple>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-button
              variant="success"
              style="min-width: 95px"
              @click="onFilter"
            >
              {{ $t('Apply') }}
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <div v-else>
      <span
        v-if="$route.query.hasOwnProperty('search')"
        class="border-dashed mr-1 d-inline-block"
      >
        <b>Search:</b> <span v-html="$route.query.search" />
        <feather-icon
          icon="LClearIcon"
          class="ml-1 cursor-pointer"
          size="10"
          @click="removeSearchFilter()"
        />
      </span>
      <span
        v-for="selectedFilter in selectedFilters"
        :key="selectedFilter.path.join('.')"
        class="border-dashed mr-1 d-inline-block mb-1"
      >
        <span v-html="selectedFilter.value" />
        <feather-icon
          icon="LClearIcon"
          class="ml-1 cursor-pointer"
          size="10"
          @click="removeSelectedFilter(selectedFilter.path)"
        />
      </span>
    </div>
  </transition>
</template>

<script>
import {
  BForm, BButton, BRow, BCol,
  BFormGroup, BFormInput, BTableSimple, BThead, BFormCheckbox,
} from 'bootstrap-vue'
import LSelectFilter from '@/views/components/filters/formComponents/LSelectFilter.vue'
// eslint-disable-next-line import/no-cycle
import InventoryAssets from '@/views/components/filters/fields/InventoryAssets'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'InventoryAssetsFilterComponent',
  components: {
    BForm,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    LSelectFilter,
    BFormInput,
    BTableSimple,
    BThead,
    BFormCheckbox,
    Treeselect,
  },
  props: {
    filterToggle: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: [Array, Object],
      default: () => ({}),
    },
    filterClass: {
      type: [Object, null],
      default: null,
    },
    selectedFilters: {
      type: [Array],
      default: () => ([]),
    },
  },
  data() {
    return {
      filterCustomer: {},
    }
  },
  computed: {
    Assets() {
      return InventoryAssets
    },
    inventoryWarehouseLocations() {
      return this.$store.state[this.Assets.MODULE_NAME].warehouses?.locations ?? []
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
  },
  watch: {
    filterToggle(bool) {
      if (bool) {
        this.getLocations()
      }
    },
  },
  methods: {
    onFilter() {
      this.$emit('onFilter', this.filter)
    },
    removeSelectedFilter(path) {
      this.filterClass.removeSelectedFilter(this.filters, path)
      this.$emit('onRemoveSelectedFilter', path)
    },
    removeSearchFilter() {
      this.$emit('searchQueryClear')
    },
    getLocations() {
      this.$store.dispatch(`${this.Assets.MODULE_NAME}/getWarehouses`, { id: this.warehouse.id })
        .then(res => {
          const { data } = res.data
          // eslint-disable-next-line no-unused-vars
          const locations = []
          const fun = (node, label) => {
            // eslint-disable-next-line no-param-reassign
            node.optionLabel = label.join('-')
            const children = []
            if (node.children.length) {
              node.children.forEach(item => {
                const val = fun(item, label.concat(item.level_name))
                children.push(val)
              })
            }
            // eslint-disable-next-line no-shadow
            const res = {
              id: node.id,
              label: node.level_name,
              optionLabel: label.join(', '),
            }
            if (children.length > 0) {
              res.children = children
            }
            return res
          }
          data.locations.forEach(item => {
            locations.push(fun(item, [item.level_name]))
          })
          data.locations = locations
          this.$store.commit(`${this.Assets.MODULE_NAME}/SET_FOR_INVENTORY_LOCATION_WAREHOUSES`, data)
        })
    },
  },
}
</script>
<style lang="scss">
.tree-select--custom--filter {
  & .vue-treeselect__control {
    height: 38px !important;
  }
}
</style>
