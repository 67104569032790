<template>
  <v-select
    placeholder="Select..."
    :value="value"
    v-bind="getProps()"
    :options="list"
    :loading="isSelectLoading"
    :disabled="isDisabled"
    @search="onSearch"
    @input="onSelect"
  />
</template>
<script>
import vSelect from 'vue-select'

// eslint-disable-next-line import/named
import { debounce } from '@core/utils/utils'

export default {
  components: {
    vSelect,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSelectLoading: false,
      isSearchable: false,
    }
  },
  computed: {
    list() {
      return this.$store.state.listModule[this.field.store]
    },
  },
  mounted() {
    const isActiveParamAccount = new URLSearchParams(window.location.search).get('f[is_active]')
    const isActiveParamCatalog = new URLSearchParams(window.location.search).get('f[status]')

    if (this.field.store && !this.list.length) {
      this.getSelectOptions(null)
    }
    const isActiveParam = isActiveParamAccount || isActiveParamCatalog
    if (this.field.firstSelect && this.list.length > 0) {
      if (isActiveParam !== null) {
        const defaultValue = (isActiveParam === '0') ? this.list[1] : this.list[0]
        this.onSelect(defaultValue)
      } else {
        const defaultValue = this.list[0]
        this.onSelect(defaultValue)
      }
    }
  },
  methods: {
    getProps() {
      return {
        label: 'name',
        ...(this.field.options ?? {}),
      }
    },
    getSelectOptions(params) {
      this.isSelectLoading = true
      this.$store.dispatch(`listModule/${this.field.store}`, params)
        .then(res => {
          if (params === null && !this.value && this.field.firstSelect) {
            this.onSelect(this.list[0])
          }
          if (!res) return
          const body = res.data.data
          if (body && body.per_page) {
            this.isSearchable = true
          }
        })
        .finally(() => {
          this.isSelectLoading = false
        })
    },
    onSelect(e) {
      // if (e.id === true) {
      //   this.$props.isDisabled = true
      // } else this.$props.isDisabled = false
      this.$emit('input', e)
    },
    onSearch(searchQuery, loading) {
      // if (this.isSearchable) {
      loading(true)
      this.search(searchQuery, this)
      // }
    },
    search: debounce((searchQuery, ctx) => {
      // eslint-disable-next-line no-prototype-builtins
      ctx.getSelectOptions({ [ctx.field.hasOwnProperty('filterBy') ? ctx.field.filterBy : 'search']: searchQuery })
    }, 500),
  },
}

</script>
<style>
.vs__actions>svg{
  width: 25px;
  height: 25px;
}
</style>
